import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useWindowSize } from "../util"
import styled from "styled-components"
import { Dialog } from "@reach/dialog"
import "@reach/dialog/styles.css"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "./overrides.css"
import { FaImages } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai"
import { useSwipeable } from "react-swipeable"
import { theme } from "../theme"

const Img = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;
  padding: 10px;
`

const MobileImg = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;
`

const LightBoxImg = styled.img`
  max-width: 1200px;
  object-fit: cover;
  width: 100%;
`

const Button = styled.div`
  color: ${({ theme }) => theme.colors.text.normal};
  font-size: 34px;
  margin: 20px;
  opacity: 0.6;
  cursor: pointer;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 28px;
  left: 20px;
`

const PreviewButton = styled.div`
  cursor: pointer;
`

// Import Images
// TODO: Lightbox and Carousel should be separate.

// TODO: Need some serious mobile love

export const Lightbox = ({ imageArray, imgIndex, open }) => {
  const [openLightbox, setOpenLightbox] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [imgArrayLength] = useState(imageArray.length)

  const [width] = useWindowSize()

  const isFirstRun = useRef(true)
  useEffect(() => {
    setSelectedImageIndex(imgIndex)
  }, [imgIndex])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    setOpenLightbox(true)
  }, [open])

  const handlers = useSwipeable({
    onSwipedRight: () =>
      selectedImageIndex > 0
        ? setSelectedImageIndex(selectedImageIndex - 1)
        : setSelectedImageIndex(selectedImageIndex),
    onSwipedLeft: () =>
      selectedImageIndex < imageArray.length - 1
        ? setSelectedImageIndex(selectedImageIndex + 1)
        : setSelectedImageIndex(selectedImageIndex),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <Fragment>
      <div style={{ position: "relative" }}>
        {width > 768 ? (
          <Carousel
            additionalTransfrom={0}
            arrows={true}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="max-container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 2.5,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {imageArray &&
              imageArray.map((item, i) => (
                <PreviewButton
                  key={i}
                  type="button"
                  onClick={() => {
                    setOpenLightbox(true)
                    setSelectedImageIndex(i)
                  }}
                >
                  <Img src={item.source_url} />
                </PreviewButton>
              ))}
          </Carousel>
        ) : (
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="max-container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 0,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {imageArray &&
              imageArray.map((item, i) => (
                <PreviewButton
                  key={i}
                  type="button"
                  onClick={() => {
                    setOpenLightbox(true)
                    setSelectedImageIndex(i)
                  }}
                >
                  <MobileImg src={item.source_url} />
                </PreviewButton>
              ))}
          </Carousel>
        )}

        <ButtonContainer>
          <button
            className="button is-light"
            type="button"
            onClick={() => setOpenLightbox(true)}
          >
            <FaImages style={{ marginRight: width > 768 ? 10 : 0 }} />
            {width > 768 ? "View All Photos" : ""}
          </button>
        </ButtonContainer>
      </div>
      {openLightbox && (
        <Dialog
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <Button
            style={{
              position: "absolute",
              right: 5,
              top: 0,
              margin: 0,
              color: theme.colors.black,
            }}
            onClick={() => setOpenLightbox(false)}
          >
            <IoMdClose />
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {selectedImageIndex > 0 && width > 768 && (
              <Button
                onClick={() => setSelectedImageIndex(selectedImageIndex - 1)}
              >
                <AiOutlineLeftCircle />
              </Button>
            )}
            {imageArray[selectedImageIndex] && (
              <LightBoxImg
                src={imageArray[selectedImageIndex].source_url}
                {...handlers}
              />
            )}
            {selectedImageIndex < imageArray.length - 1 && width > 768 && (
              <Button
                onClick={() => setSelectedImageIndex(selectedImageIndex + 1)}
              >
                <AiOutlineRightCircle />
              </Button>
            )}
          </div>
        </Dialog>
      )}
    </Fragment>
  )
}
