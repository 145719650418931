import React, { useState } from "react"
import { graphql } from "gatsby"
import { useWindowSize } from "../util"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { theme } from "../theme"
import { Lightbox } from "../components/lightbox"
import { FaBath, FaBed } from "react-icons/fa"
import { useModal } from "../context/modal"

import MapboxGLMap from "../components/map"
import { Waypoint } from "react-waypoint"

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  line-height: 120%;
  margin-bottom: ${({ theme }) => theme.space[3]};
`

const Subtitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  line-height: 120%;
  margin-bottom: ${({ theme }) => theme.space[4]};
`

const TextContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(91.66659% - 16px);
  max-width: calc(91.66659% - 16px);
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.text};

  @media (max-width: 768px) {
    flex-basis: calc(100%);
    max-width: calc(100%);
    font-size: 13px;
  }
`

const SectionAlternateTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.2;
`
const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`

const ImgSubtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.text.normal};
`

const SquareImgContainer = styled.div`
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
`

const ListingTemplate = ({ data }) => {
  const [modal, setModal] = useModal()
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [toggleFlexbox, setToggleFlexbox] = useState(false)
  const [width] = useWindowSize()
  const [showSideNav, setShowSideNav] = useState(true)

  return (
    <Layout>
      <SEO
        title={data.wordpressWpListing.title}
        description={
          data.wordpressWpListing.acf.seo_description
            ? data.wordpressWpListing.acf.seo_description
            : "Nikhil Bhanwra, nikhilbhanwra.com, Judy Marsales, Hamilton, Ontario, Burlington, Real Estate"
        }
      />

      <Lightbox
        imageArray={data.wordpressWpListing.acf.gallery.map(item => item)}
        imgIndex={selectedImageIndex}
        open={toggleFlexbox}
      />
      <section className="section">
        <div
          className="container"
          style={{ maxWidth: 1064, padding: width > 768 ? `3rem 0` : `0` }}
        >
          <div className="columns">
            <div className="column is-two-thirds">
              <TextContainer>
                <Title>{data.wordpressWpListing.acf.listing_address}</Title>
                <Subtitle>{data.wordpressWpListing.acf.listing_city}</Subtitle>
                <div
                  className="columns is-mobile is-multiline"
                  style={{ maxWidth: 500 }}
                >
                  <div className="column is-half-mobile">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 20,
                      }}
                    >
                      <FaBed
                        style={{
                          fontSize: 25,
                          color: theme.colors.text.normal,
                          marginRight: 10,
                        }}
                      />
                      {data.wordpressWpListing.acf.building.bedrooms}{" "}
                      {data.wordpressWpListing.acf.building.bedrooms === "1"
                        ? "Bedroom"
                        : "Bedrooms"}
                    </div>
                  </div>
                  <div className="column is-half-mobile">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 20,
                      }}
                    >
                      <FaBath
                        style={{
                          fontSize: 25,
                          color: theme.colors.text.normal,
                          marginRight: 10,
                        }}
                      />
                      {data.wordpressWpListing.acf.building.bathrooms}{" "}
                      {data.wordpressWpListing.acf.building.bathrooms === "1"
                        ? "Bathroom"
                        : "Bathrooms"}
                    </div>
                  </div>
                  <div className="column">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: 20,
                      }}
                    >
                      {data.wordpressWpListing.acf.building.type_of_home}
                    </div>
                  </div>
                </div>
                <div
                  className="listing-body"
                  style={{ marginTop: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressWpListing.content,
                  }}
                />
                <br />
                <br />
                <SectionAlternateTitle>The House</SectionAlternateTitle>

                <div className="columns is-multiline is-mobile is-2 is-variable">
                  {data.wordpressWpListing.acf.gallery.map((img, i) => (
                    <div
                      className="column is-half-mobile is-half-tablet is-one-third-desktop"
                      key={i}
                    >
                      <SquareImgContainer>
                        <Img
                          src={img.source_url}
                          onClick={() => {
                            setSelectedImageIndex(i)
                            setToggleFlexbox(!toggleFlexbox)
                          }}
                        />
                      </SquareImgContainer>
                      <ImgSubtitle>{img.title}</ImgSubtitle>
                      <ImgSubtitle
                        dangerouslySetInnerHTML={{ __html: img.caption }}
                      ></ImgSubtitle>
                    </div>
                  ))}
                </div>

                <br />
                <br />
                <SectionAlternateTitle>Map</SectionAlternateTitle>
                <MapboxGLMap
                  height="300px"
                  lon={data.wordpressWpListing.acf.location.longitude}
                  lat={data.wordpressWpListing.acf.location.latitude}
                  markerArray={[
                    {
                      lon: data.wordpressWpListing.acf.location.longitude,
                      lat: data.wordpressWpListing.acf.location.latitude,
                      address: data.wordpressWpListing.acf.listing_address,
                      image: data.wordpressWpListing.acf.main_image.source_url,
                      slug: data.wordpressWpListing.slug,
                    },
                  ]}
                />
              </TextContainer>
            </div>
            {width > 768 ? (
              <div className="column is-one-third">
                <div
                  className="card"
                  style={{ background: theme.colors.secondary[2] }}
                >
                  <div className="card-content">
                    <p className="title" style={{ marginBottom: 50 }}>
                      {data.wordpressWpListing.acf.price}
                    </p>
                    {data.wordpressWpListing.acf.sidebar_details && (
                      <p className="subtitle">
                        {data.wordpressWpListing.acf.sidebar_details}
                      </p>
                    )}
                  </div>
                  {data.wordpressWpListing.acf.action_button && (
                    <footer className="card-footer">
                      <button
                        className="card-footer-item button"
                        style={{
                          background: theme.colors.main[2],
                          color: theme.colors.white,
                          padding: 0,
                        }}
                        onClick={() => setModal(!modal)}
                      >
                        {data.wordpressWpListing.acf.action_button}
                      </button>
                    </footer>
                  )}
                </div>
              </div>
            ) : (
              <div>
                {showSideNav && (
                  <nav
                    className="navbar is-fixed-bottom"
                    style={{
                      borderTop: `1px solid ${theme.colors.secondary[0]}`,
                      background: `${theme.colors.secondary[0]}`,
                      zIndex: 100,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: 22,
                        height: 80,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 24,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.wordpressWpListing.acf.price}
                      </div>
                      {data.wordpressWpListing.acf.action_button && (
                        <div>
                          <button
                            className="button"
                            onClick={() => setModal(!modal)}
                          >
                            {data.wordpressWpListing.acf.action_button}
                          </button>
                        </div>
                      )}
                    </div>
                  </nav>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      {width < 768 && (
        <nav
          className="navbar"
          style={{
            borderTop: `1px solid ${theme.colors.secondary[0]}`,
            background: `${theme.colors.secondary[0]}`,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 22,
              height: 80,
            }}
          >
            <div style={{ fontSize: 24 }}>
              {data.wordpressWpListing.acf.price}
            </div>
            {data.wordpressWpListing.acf.action_button && (
              <div>
                <button
                  className="button"
                  onClick={() => {
                    setModal(!modal)
                  }}
                >
                  {data.wordpressWpListing.acf.action_button}
                </button>
              </div>
            )}
          </div>
        </nav>
      )}

      <Waypoint
        onLeave={({ previousPosition, currentPosition, event }) => {
          if (currentPosition === "below") {
            setShowSideNav(!showSideNav)
          }
        }}
        onEnter={({ previousPosition, currentPosition, event }) => {
          if (previousPosition === "below") {
            setShowSideNav(!showSideNav)
          }
        }}
      />
    </Layout>
  )
}
export default ListingTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressWpListing(wordpress_id: { eq: $id }) {
      id
      title
      content
      slug
      wordpress_id
      acf {
        listing_address
        listing_city
        previous_price
        price
        action_button
        sidebar_details
        main_image {
          source_url
          title
          caption
        }
        building {
          bathrooms
          bedrooms
          type_of_home
        }
        gallery {
          id
          source_url
          title
          caption
        }
        location {
          latitude
          longitude
        }
        seo_description
      }
    }
  }
`
